.container {
  display: flex;
  border-radius: 15px;
  height: 120px;
  background-color: rgb(240, 240, 240);
  box-shadow: 0 5px 10px rgba(0,0,0,0.1), 0 2px 2px rgba(0,0,0,0.1);
  /* justify-content: space-between; */
}

.container:hover {
  box-shadow: 0 8px 15px rgba(0,0,0,0.2), 0 4px 4px rgba(0,0,0,0.2);
  /* cursor: pointer; */
}

.title {
  font-size: 19px;
  font-weight: 300;
  padding-left: 20px;
  padding-top: 17px;
}

.subtitle {
  font-size: medium;
  font-weight: normal;
  padding-left: 20px;
  color: #818391
}

.icon {
  padding-top: 15px;
  height: 5.3rem;
  width: 4.8rem;
}

.iconContainer {
  padding-top: 5px;
  padding-left: 5px;
}

.full {
  width: 100%;
  height: 411px;
  border-radius: 15px;
}

.full:hover {
  cursor: pointer;
}

@media only screen and (min-width: 200px) and (max-width: 575px) {
  .title {
    font-size: 14.5px;
  }
  .subtitle {
    font-size: 11.5px;
  }
  .icon {
    padding-top: 15px;
    height: 4rem;
    width: 3.5rem;
  }
  .full {
    height: auto;
  }
  .container {
    height: 95px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 992px) {
  .title {
    font-size: 18px;
  }
  .subtitle {
    font-size: 14px;
  }
  .icon {
    padding-top: 15px;
    height: 4rem;
    width: 3.5rem;
  }
  .container {
    height: 100px;
  }
  .full {
    height: auto;
  }
}