.container {
  display: block;
  height: 5rem;
  background-color: rgb(247, 247, 247);
  /* box-shadow: 0 1px 1px rgba(0,0,0,0.19), 0 4px 4px rgba(0,0,0,0.23) */
}

.inline {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: auto;
}

.logo {
  margin-top: 7px;
  width: 10rem;
  height: 3rem;
}

.logo:hover {
  cursor: pointer;
}

.logout {
  padding-top: 1.7rem;
  height: auto;
  border: 0;
  text-decoration: none;
}

.logout button:focus {
  outline: 0;
  border: 0;
}

.logout button {
  border: 0;
  background-color: inherit;
  font-size: 18px;
  color: #212529
}

.logout button:hover {
  color: black
}