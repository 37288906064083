.container {
  background-color: #333;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.remoteMedia {
  display: flex;
  position: relative;
  width: 70em;
  /* background-color: green; */
}

.control {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* width: 10em; */
  padding: 0.5em;
  position: absolute;
  bottom: 0;
  right: 0;
}

.errorDiv {
  /* background-color: rgb(192, 3, 3); */
  color: white;
  padding: 15px;
  border-radius: 15px;
  z-index: 1;
  width: fit-content;
  text-align: center;
  height: fit-content;
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  top: 30px;
  word-wrap: break-word;
  transition: 1s ease-in;
}

.alert {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alertText {
  background-color: #cce5ff;
  border-color: #b8daff;
  border-radius: 5px;
  padding: 5px;
}

.localMedia {
  display: flex;
  position: relative;
  height: 10em;
  width: 40vw;
  /* background-color: blue; */
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-color: red;
}

.iconContainer {
  background-color: red;
  /* height: 2em;
  width: 2em; */
  align-self: center;
  border-radius: 50%;
  margin: 0.5em;
  padding: 1em;
  cursor: pointer;
}

.icon {
  color: white;
}
