.container {
  width: 85%;
  margin: auto;
  height: 80%;
}

.flexing {
  display: flex;
  align-content: space-between;
  height: 100%;
  align-items: center;
}

.appointment {
  width: 100%;
  padding-left: 3px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.appointments {
  display: flex;
  flex: 100%;
  height: 85%;
  flex-direction: column;
  /* justify-content: space-between; */
  max-height: 90%;
}

.scroll {
  overflow-y: scroll;
  /* height: 200px; */
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.bold {
  font-weight: bold;
}

.light {
  font-weight: 400;
  padding-left: 7px;
}

.construction {
  width: 100%;
  height: 85%;
  margin-top: 5em;
}

.video {
  padding-bottom: 10px;
  padding-right: 20px;
}

.high {
  height: 25em;
  width: 100%;
}

.name {
  height: 5em;
  display: flex;
  align-items: flex-end;
}

.calling {
  background-color:#00D963;
  color: rgb(255, 255, 255);
}

.calling:hover {
  cursor: pointer;
}

@media only screen and (min-width: 801px) and (max-width: 992px) {
  .container {
    width: 90%;
  }
 }

 @media only screen and (min-width: 576px) and (max-width: 800px) {
   .container {
     width: 70%;
   }
   .flexing {
     flex-direction: column;
      padding-top: 20px;
      height: auto;
   }
   .appointments {
     width: 100%;
  }
  .appointment {
    padding-right: 0;
   }
   .calling {
    width: 105%;
   }
   .construction {
     padding-top: 15px;
     padding-bottom: 15px;
   }
   .container h4 {
     font-size: 22px;
   }
   .high {
    height: 22em;
  }
  .scroll {
    overflow: hidden;
  }
 }

 @media only screen and (min-width: 200px) and (max-width: 575px) {
  .container {
    width: 90%;
  }
  .container h4 {
    font-size: 19px;
  }
  .flexing {
    flex-direction: column;
    height: auto;
  }
  .appointments {
    width: 100%;
  }
  .appointment {
   padding-right: 0;
  }
  .calling {
    width: 105%;
   }
  .construction {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .high {
    height: 20em;
  }
  .scroll {
    overflow: hidden;
  }
 }
